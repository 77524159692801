import { useEffect, useState } from "react";

export const useMediaQuery = (query: string): boolean => {
  const [isMatch, setMatch] = useState<boolean>(false);

  useEffect(() => {
    setMatch(window.matchMedia && window.matchMedia(query).matches);

    const updateQueryScheme = () =>
      setMatch(window.matchMedia && window.matchMedia(query).matches);

    window.matchMedia(query).addEventListener("change", updateQueryScheme);

    return () => {
      window.matchMedia(query).removeEventListener("change", updateQueryScheme);
    };
  }, [query]);

  return isMatch;
};
