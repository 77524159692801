import React from "react";

export interface IBoxProps extends React.CSSProperties {
  className?: string;
  children?: React.ReactNode;
  sx?: React.CSSProperties;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  id?: string;
  ref?: React.RefObject<HTMLDivElement>;
}

export const Box: React.FC<IBoxProps> = ({
  children,
  sx,
  onClick,
  id,
  ref,
  className,
  ...props
}) => {
  return (
    <div
      className={className}
      id={id}
      ref={ref}
      onClick={onClick}
      style={{ ...props, ...sx }}
    >
      {children}
    </div>
  );
};
