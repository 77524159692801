import React from "react";
import { ReactComponent as Linkedin } from "../../assets/linkedin.svg";
import { ReactComponent as Instagram } from "../../assets/instagram.svg";
import "../../App.css";
import { IStyle, DESKTOP_SCREEN } from "../../constants";
import { Box } from "../../components/atoms/box";
import { Typography } from "../../components/atoms/typography";
import { useMediaQuery } from "../../components/media_query_hook";

const useStyle = (desktopView: boolean): IStyle => ({
  homeScreenView: {
    height: "calc(100% - 100px)",
    width: "100%",
    backgroundColor: "var(--black)",
    position: "relative",
    justifyContent: "center",
    alignContent: "end",
  },
  blurGradient: {
    position: "absolute",
    top: "45%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "298px",
    height: "304px",
    background: "var(--light-blue)",
    opacity: "0.5",
    filter: "blur(150px)",
  },
  headerText: {
    position: "absolute",
    top: "45%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    fontSize: desktopView ? "64px" : "20px",
    fontFamily: '"Archivo Black", sans-serif',
    color: "var(--light-green)",
  },
  bottomFooter: {
    position: "relative",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: "5vw",
    paddingRight: "5vw",
    height: "8%",
    width: "100%",
    backgroundColor: "var(--light-green)",
  },
  footerIcon: {
    display: "flex",
    width: "100px",
    justifyContent: "space-between",
  },
  footerText: {
    fontSize: desktopView ? "12px" : "10px",
  },
});

const HomeScreen: React.FC = () => {
  const desktopView = useMediaQuery(DESKTOP_SCREEN);
  const style = useStyle(desktopView);
  return (
    <Box sx={style.homeScreenView}>
      <Box sx={style.blurGradient}></Box>
      <Typography sx={style.headerText}>COMING SOON</Typography>
      <Box sx={style.bottomFooter}>
        <Box sx={style.footerText}>© 2024 All Rights Reserved</Box>
        <Box sx={style.footerIcon}>
          <Linkedin />
          <Instagram />
        </Box>
      </Box>
    </Box>
  );
};

export default HomeScreen;
