import "./App.css";
import NavBar from "./components/compunds/nav-bar/nav_bar";
import { Route, Routes } from "react-router-dom";
import {
  HomeScreen,
  ProductScreen,
  ServiceScreen,
  AboutUsScreen,
} from "./routing_list";

function App() {
  return (
    <>
      <NavBar />
      <Routes>
        <Route path="/" element={<HomeScreen />} />
        <Route path="/product" element={<ProductScreen />} />
        <Route path="/service" element={<ServiceScreen />} />
        <Route path="/aboutUs" element={<AboutUsScreen />} />
      </Routes>
    </>
  );
}

export default App;
