import React from "react";
import "./service_screen.scss";

import "../../App.css";

const ServiceScreen: React.FC = () => {
  return <div className="serviceScreenView"></div>;
};

export default ServiceScreen;
