import React from "react";
import "./product_screen.scss";

import "../../App.css";

const ProductScreen: React.FC = () => {
  return <div className="productScreenView"></div>;
};

export default ProductScreen;
