export const SMALL_MOBILE_SCREEN = " (max-width : 300px)";
export const SE_MOBILE_SCREEN = " (max-width : 380px)";
export const MOBILE_SCREEN = " (min-width : 100px) and (max-width : 640px)";
export const IPAD_SCREEN = " (min-width : 641px) and (max-width : 1007px)";
export const MOBILE_IPAD_SCREEN =
  " (min-width : 100px) and (max-width : 1007px)";
export const SMALL_IPAD_SCREEN =
  " (min-width : 1020px) and (max-width : 1100px)";
export const DESKTOP_SCREEN = "(min-width : 1007px)";
export type IStyle = Record<string, React.CSSProperties>;
