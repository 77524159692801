import React from "react";
import "./about_us_screen.scss";

import "../../App.css";

const AboutUsScreen: React.FC = () => {
  return <div className="aboutUsScreenView"></div>;
};

export default AboutUsScreen;
