import React from "react";
import { Link } from "react-router-dom";
import "./nav_bar.scss";

const Navbar: React.FC = () => {
  return (
    <div className="navBox">
      <Link to="/" className="foster-header">
        Foster
      </Link>
      <div className="right-elements">
        {/* <Link to="/product" className="product">
          Product
        </Link>

        <Link to="/service" className="service">
          Service
        </Link>

        <Link to="/aboutUs" className="aboutUs">
          About Us
        </Link> */}
      </div>
    </div>
  );
};

export default Navbar;
